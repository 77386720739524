import {
  filter,
  isNil,
  map,
  prop,
  propEq,
  reduce,
  reverse,
  sortBy,
} from 'ramda';
import formatYear from '../../../utils/formatYear';
import sortByYear from '../../../utils/sortByYear';
import {
  categoriesParticipationRate,
  categoriesStudentCount,
} from '../../../constants/studentCategories';

const getFormFilter = activeFilters =>
  filter(
    ({ key }) => activeFilters.length === 0 || activeFilters.includes(key)
  );

const absenceLabelMap = {
  immunity: 'Vrijstelling',
  longLastingRelativeAbsence: 'Langdurig relatief verzuim',
  moreThanThreeLongLastingRelativeAbsence:
    'Langdurig relatief verzuim > 3 maanden',
  totalAbsoluteAbsence: 'Absoluut verzuim totaal',
  moreThanThreeAbsoluteAbsence: 'Absoluut verzuim > 3 maanden',
};

export const formatParticipationRate = (
  indicator,
  sector,
  activeFilters = []
) => {
  const filterForms = getFormFilter(activeFilters);
  const _data = reduce(
    (acc, _year) => {
      const formsInSector = categoriesParticipationRate[sector];
      const year = formatYear(_year);
      acc[year] = map(
        ({ key, label }) => ({
          label,
          values: [
            {
              value: isNil(indicator[_year][key]) ? 0 : indicator[_year][key],
            },
          ],
        }),
        filterForms(formsInSector)
      );

      return acc;
    },
    {},
    sortByYear(Object.keys(indicator))
  );

  return _data;
};

export const formatStudentCount = (indicator, _sector, activeFilters = []) => {
  const filterForms = getFormFilter(activeFilters);
  const _data = reduce(
    (acc, _year) => {
      const formsInSector = categoriesStudentCount[_sector];
      const year = formatYear(_year);
      const sector = _sector === 'primair-onderwijs' ? 'PO' : 'VO';
      acc[year] = reverse(
        sortBy(
          prop('value'),
          map(
            ({ name, studentCount }) => ({
              dataKey: name,
              value: reduce(
                (acc, key) => acc + studentCount[key],
                0,
                map(prop('key'), filterForms(formsInSector))
              ),
            }),
            filter(propEq('sector', sector), indicator[_year], sector)
          )
        )
      );

      return acc;
    },
    {},
    sortByYear(Object.keys(indicator))
  );

  return _data;
};

export const formatAbsence = (indicator, activeMultiButton) => {
  const _data = reduce(
    (acc, _year) => {
      const year = formatYear(_year);
      acc[year] = map(
        key => ({
          label: absenceLabelMap[key],
          values: [
            { value: isNil(indicator[_year][key]) ? 0 : indicator[_year][key] },
          ],
        }),
        activeMultiButton.visibleKeys
      );

      return acc;
    },
    {},
    sortByYear(Object.keys(indicator))
  );

  return _data;
};
